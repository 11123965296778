<template>
    <app-drawer :menus="menus" v-if="team" color="cyan darken-3" />
</template>

<script>
import { mapGetters } from 'vuex';
import appDrawer from './drawer';

export default {
    name: 'drawer-team',
    components: {
        appDrawer,
    },
    computed: {
        ...mapGetters(['team', 'profile']),
        allMenus() {
            return [
                {
                    to: 'team-active-sprint',
                    icon: 'mdi-reiterate',
                    label: 'drawer.teams.team-sprint',
                    position: 'top',
                },
                {
                    to: 'team-sprints',
                    icon: 'mdi-format-list-numbered',
                    label: 'drawer.teams.team-sprints',
                    position: 'top',
                },
                {
                    to: 'team-settings',
                    icon: 'mdi-tune-vertical',
                    label: 'drawer.teams.team-settings',
                    position: 'bottom',
                    roles: ['productManager'],
                },
            ];
        },
        teamUser() {
            if (this.team) {
                return this.team.members.filter((m) => m.user === this.profile.name)[0];
            }
            return undefined;
        },
        menus() {
            const menus = this.allMenus.filter((m) => {
                if (this.profile.security.administration) {
                    return true;
                }
                if (m.roles && this.teamUser) {
                    return m.roles.some((r) => this.teamUser.roles.includes(r));
                } else {
                    return true;
                }
            });

            return menus;
        },
    },
};
</script>
